<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.728" x2="0.193" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="Document" transform="translate(-210 -368)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(210 368)" width="60"/>
    <path d="M13.125,7.969V0H1.406A1.4,1.4,0,0,0,0,1.406V28.594A1.4,1.4,0,0,0,1.406,30H21.094A1.4,1.4,0,0,0,22.5,28.594V9.375H14.531A1.41,1.41,0,0,1,13.125,7.969ZM22.5,7.143V7.5H15V0h.357a1.405,1.405,0,0,1,1,.41L22.09,6.152A1.4,1.4,0,0,1,22.5,7.143Z" data-name="Icon awesome-file" id="Icon_awesome-file" style="fill: url(#linear-gradient);" transform="translate(229 383)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>